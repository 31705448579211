import {
  Avatar,
  AvatarImage,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Dialog,
  DialogContent,
} from "@mindstonehq/ui";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "@remix-run/react";

import { useCurrentSpace, useUpdateTheme, useUser } from "~/hooks/context-hook";
import { CommandPaletteContext } from "~/components/command-palette/command-palette-context";
import { useSearchMyResourcesQuery } from "~/graphql/__generated";
import { NavigationItem } from "~/components/containers/app-layout";
import { MoonIcon, MoonStar, SunMoonIcon } from "lucide-react";

export default function CommandPalette(props: React.PropsWithChildren<{}>) {
  const [open, setOpen] = useState(false);
  const currentSpace = useCurrentSpace();
  const navigate = useNavigate();
  const [navigation, setNavigation] = useState<NavigationItem[]>([]);
  const updateTheme = useUpdateTheme();
  const preferences = useMemo(
    () => [
      {
        id: "light",
        label: "Theme - Light",
        icon: SunMoonIcon,
        action: () => updateTheme("light"),
      },
      {
        id: "dark",
        label: "Theme - Dark",
        action: () => updateTheme("dark"),
        icon: MoonIcon,
      },
      {
        id: "system",
        label: "Theme - System",
        action: () => updateTheme(""),
        icon: MoonStar,
      },
    ],
    [],
  );

  const [filter, setFilter] = useState<string | null>(null);
  const location = useLocation();
  const user = useUser();
  const contextValue = React.useMemo(
    () => ({
      toggle: () => setOpen((open) => !open),
      registerNavigation: (navigation: NavigationItem[]) =>
        setNavigation(navigation),
    }),
    [setNavigation, setOpen],
  );

  const { data: resourceSearch, loading: searchLoading } =
    useSearchMyResourcesQuery({
      skip: !filter,
      variables: {
        offset: 0,
        limit: 5,
        searchTerm: filter || "",
      },
    });
  useEffect(() => {
    if (!open) {
      setFilter("");
    }
  }, [open]);

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  const filteredNavigation = navigation.filter(
    (x) =>
      x.available(currentSpace, user) &&
      x.name.toLowerCase().includes(filter?.toLowerCase() || ""),
  );
  const filteredPreferences = preferences.filter((x) =>
    x.label.toLowerCase().includes(filter?.toLowerCase() || ""),
  );

  return (
    <CommandPaletteContext.Provider value={contextValue}>
      <Dialog {...props} open={open} onOpenChange={setOpen}>
        <DialogContent className="overflow-hidden p-0 shadow-lg">
          <Command shouldFilter={false} loop>
            <CommandInput
              placeholder="Type a command or search..."
              onValueChange={setFilter}
            />
            <CommandList>
              {filteredNavigation.length > 0 && (
                <CommandGroup heading="Navigation">
                  {filteredNavigation
                    // .sort((x, y) => x.name.localeCompare(y.name))
                    .map((x) => {
                      const Icon = x.icon;
                      return (
                        <CommandItem
                          key={x.name}
                          onSelect={() => {
                            navigate(x.href || "/");
                            setOpen(false);
                          }}
                        >
                          <Icon className="mr-2 h-4 w-4" />
                          <span>{x.name}</span>
                        </CommandItem>
                      );
                    })}
                  <CommandSeparator />
                </CommandGroup>
              )}
              {!!filteredPreferences.length && (
                <CommandGroup heading="Preferences">
                  {filteredPreferences
                    // .sort((x, y) => x.name.localeCompare(y.name))
                    .map((x) => {
                      const Icon = x.icon;
                      return (
                        <CommandItem
                          key={x.id}
                          onSelect={() => {
                            x.action();
                            setOpen(false);
                          }}
                        >
                          <Icon className="mr-2 h-4 w-4" />
                          <span>{x.label}</span>
                        </CommandItem>
                      );
                    })}
                  <CommandSeparator />
                </CommandGroup>
              )}
              {(resourceSearch || searchLoading) && (
                <CommandGroup heading="Content">
                  {searchLoading && (
                    <CommandItem disabled>Searching...</CommandItem>
                  )}
                  {resourceSearch?.searchMyResources?.resources?.map((x) => {
                    return (
                      <CommandItem
                        key={x?.id}
                        value={x?.id}
                        onSelect={() => {
                          navigate(`/annotate/${x?.id}`, {
                            state: { back: { ...location } },
                          });
                          setOpen(false);
                        }}
                        className="flex flex-row gap-2"
                      >
                        <Avatar className="h-7 w-7 rounded-sm shrink-00">
                          <AvatarImage src={x?.images.thumbnail} />
                        </Avatar>

                        <span>{x?.headline}</span>
                      </CommandItem>
                    );
                  })}
                  {(resourceSearch?.searchMyResources?.totalHits || 0) > 5 && (
                    <CommandItem
                      key={"search_see_all"}
                      value={"search_see_all"}
                      onSelect={() => {
                        navigate(`/search/${filter}`);
                        setOpen(false);
                      }}
                      className="flex flex-row gap-2"
                    >
                      See all {resourceSearch?.searchMyResources?.totalHits}{" "}
                      results
                    </CommandItem>
                  )}
                  <CommandEmpty>No results found.</CommandEmpty>
                </CommandGroup>
              )}
            </CommandList>
          </Command>
        </DialogContent>
      </Dialog>
      {props.children}
    </CommandPaletteContext.Provider>
  );
}
