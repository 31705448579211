import React, { useEffect } from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useUser } from "~/hooks/context-hook";
import { useConfig } from "~/hooks/webconfig";

export default function IntercomInitializer({
  children,
}: React.PropsWithChildren<{}>) {
  const config = useConfig();
  return (
    <IntercomProvider
      appId={config.intercomAppId}
      autoBoot
      autoBootProps={{
        hideDefaultLauncher: true,
        customLauncherSelector: ".ms-intercom-launcher",
      }}
    >
      <IntercomInit>{children}</IntercomInit>
    </IntercomProvider>
  );
}

function IntercomInit({ children }: React.PropsWithChildren<{}>) {
  const user = useUser();
  const { update } = useIntercom();
  useEffect(() => {
    // @ts-ignore
    if (!window.intercomSettings) window.intercomSettings = {};
    if (user) {
      update({
        name: user.name || "",
        email: user.email,
        avatar: {
          type: "avatar",
          imageUrl: user.profilePictureURL || "",
        },
        userId: user.id,
      });
    }
  }, [user]);
  return <>{children}</>;
}
